const cityData = [
    {
        "0": [
            {
                "city": "Астана",
                "index": "010000",
                "idrive": true
            }
        ],
        "1": [
            {
                "city": "Алматы",
                "index": "050000"
            }
        ],
        "2": [
            {
                "city": "Арыс",
                "index": "160100"
            },
            {
                "city": "Шолаккорган",
                "index": "161000"
            },
            {
                "city": "Сарыагаш",
                "index": "160900"
            },
            {
                "city": "Шымкент",
                "index": "160000"
            },
            {
                "city": "Туркестан",
                "index": "161200"
            },
            {
                "city": "Темирлановка",
                "index": "160600"
            },
            {
                "city": "Шаян",
                "index": "160200"
            },
            {
                "city": "Жетысай",
                "index": "160500"
            },
            {
                "city": "Казыгурт",
                "index": "160300"
            },
            {
                "city": "Турар Рыскулов",
                "index": "161300"
            },
            {
                "city": "Аксукент",
                "index": "160800"
            },
            {
                "city": "Шардара",
                "index": "161400"
            },
            {
                "city": "Шаульдер",
                "index": "160700"
            },
            {
                "city": "Ленгер",
                "index": "161100"
            },
            {
                "city": "Атакент",
                "index": "160525"
            }
        ],
        "3": [
            {
                "city": "Коргалжын",
                "index": "021300"
            },
            {
                "city": "Ерейментау",
                "index": "020800"
            },
            {
                "city": "Аршалы",
                "index": "020200"
            },
            {
                "city": "Косшы",
                "index": "010078"
            },
            {
                "city": "Кокшетау",
                "index": "020000",
                "idrive": true

            },
            {
                "city": "Макинск",
                "index": "020500"
            },
            {
                "city": "Астраханка",
                "index": "020300"
            },
            {
                "city": "Акколь",
                "index": "020100"
            },
            {
                "city": "Зеренда",
                "index": "021200"
            },
            {
                "city": "Державинск",
                "index": "021100"
            },
            {
                "city": "Сандыктау",
                "index": "021400"
            },
            {
                "city": "Есиль",
                "index": "020900"
            },
            {
                "city": "Егиндыколь",
                "index": "020600"
            },
            {
                "city": "Степняк",
                "index": "020700"
            },
            {
                "city": "Степногорск",
                "index": "021500"
            },
            {
                "city": "Шортанды",
                "index": "021600"
            },
            {
                "city": "Жаксын",
                "index": "021000"
            },
            {
                "city": "Атбасар",
                "index": "020400"
            },
            {
                "city": "Щучинск",
                "index": "021700",
                "idrive": true

            }
        ],
        "4": [
            {
                "city": "Актобе",
                "index": "030000"
            },
            {
                "city": "Кандыагаш ",
                "index": "030700"
            },
            {
                "city": "Комсомольское",
                "index": "030100"
            },
            {
                "city": "Карауылкельды (Байганин)",
                "index": "030300"
            },
            {
                "city": "Шалкар",
                "index": "031200"
            },
            {
                "city": "Шубаркудук",
                "index": "030800"
            },
            {
                "city": "Алга",
                "index": "030200"
            },
            {
                "city": "Хромтау",
                "index": "031100"
            },
            {
                "city": "Бадамша",
                "index": "030500"
            },
            {
                "city": "Уил",
                "index": "030900"
            },
            {
                "city": "Иргиз",
                "index": "030400"
            },
            {
                "city": "Мартук",
                "index": "030600"
            },
            {
                "city": "Кобда",
                "index": "031000"
            },
            {
                "city": "Эмбин",
                "index": "031300"
            }
        ],
        "5": [
            {
                "city": "Каргалы",
                "index": "040616"
            },
            {
                "city": "Сарканд",
                "index": "041500"
            },
            {
                "city": "Уштобе",
                "index": "041000"
            },
            {
                "city": "Кеген",
                "index": "041400"
            },
            {
                "city": "Текели",
                "index": "041700"
            },
            {
                "city": "Жансугуров",
                "index": "040100"
            },
            {
                "city": "Карабулак",
                "index": "040500"
            },
            {
                "city": "Есик",
                "index": "040400"
            },
            {
                "city": "Жаркент",
                "index": "041300"
            },
            {
                "city": "Талдыкорган",
                "index": "040000"
            },
            {
                "city": "Балпык",
                "index": "041200"
            },
            {
                "city": "Сарыозек",
                "index": "041100"
            },
            {
                "city": "Ушарал",
                "index": "040200"
            },
            {
                "city": "Отеген-Батыр",
                "index": "040700"
            },
            {
                "city": "Баканас",
                "index": "040300"
            },
            {
                "city": "Каскелен",
                "index": "040900"
            },
            {
                "city": "Талгар",
                "index": "041600"
            },
            {
                "city": "Чунджа",
                "index": "041800"
            },
            {
                "city": "Узынагаш",
                "index": "040600"
            },
            {
                "city": "Шелек",
                "index": "040462"
            },
            {
                "city": "Капчагай",
                "index": "040800"
            }
        ],
        "6": [
            {
                "city": "Атырау",
                "index": "060000"
            },
            {
                "city": "Аккистау",
                "index": "060300"
            },
            {
                "city": "Махамбет",
                "index": "060700"
            },
            {
                "city": "Макат",
                "index": "060600"
            },
            {
                "city": "Ганюшкино",
                "index": "060400"
            },
            {
                "city": "Кульсары",
                "index": "060100"
            },
            {
                "city": "Индерборский",
                "index": "060200"
            },
            {
                "city": "Миялы",
                "index": "060500"
            }
        ],
        "7": [
            {
                "city": "Усть-Каменогорск",
                "index": "070000"
            },
            {
                "city": "Семей",
                "index": "071400"
            },
            {
                "city": "Улькен-Нарын",
                "index": "070900"
            },
            {
                "city": "Зырянов",
                "index": "070800"
            },
            {
                "city": "Бородулиха",
                "index": "070400"
            },
            {
                "city": "Шемонаиха",
                "index": "071800"
            },
            {
                "city": "Куршым",
                "index": "071200"
            },
            {
                "city": "Калбатау ",
                "index": "070600"
            },
            {
                "city": "Аксуат",
                "index": "071500"
            },
            {
                "city": "Бескарагай",
                "index": "070300"
            },
            {
                "city": "Риддер",
                "index": "071300"
            },
            {
                "city": "Аягоз",
                "index": "070200"
            },
            {
                "city": "Касыма Кайсенова",
                "index": "071600"
            },
            {
                "city": "Урджар",
                "index": "071700"
            },
            {
                "city": "Кокпекты",
                "index": "071000"
            },
            {
                "city": "Глубокое",
                "index": "070500"
            },
            {
                "city": "Зайсан",
                "index": "070700"
            }
        ],
        "8": [
            {
                "city": "Тараз",
                "index": "080000"
            },
            {
                "city": "Кордай",
                "index": "080400"
            },
            {
                "city": "Момышулы",
                "index": "080300"
            },
            {
                "city": "Мойынкум",
                "index": "080600"
            },
            {
                "city": "Жанатас",
                "index": "080700"
            },
            {
                "city": "Мерке",
                "index": "080500"
            },
            {
                "city": "Кулан",
                "index": "080900"
            },
            {
                "city": "Аса",
                "index": "080200"
            },
            {
                "city": "Каратау",
                "index": "080800"
            },
            {
                "city": "Сарыкемер",
                "index": "080100"
            },
            {
                "city": "Шу",
                "index": "081100"
            }
        ],
        "9": [
            {
                "city": "Уральск",
                "index": "090000"
            },
            {
                "city": "Казталовка",
                "index": "090700"
            },
            {
                "city": "Чапаев",
                "index": "090100"
            },
            {
                "city": "Аксай",
                "index": "090300"
            },
            {
                "city": "Жымпиты",
                "index": "090900"
            },
            {
                "city": "Каратобин",
                "index": "090800"
            },
            {
                "city": "Фёдоровка",
                "index": "091100"
            },
            {
                "city": "Перемётное",
                "index": "090600"
            },
            {
                "city": "Сайхин",
                "index": "090200"
            },
            {
                "city": "Джангала",
                "index": "090400"
            },
            {
                "city": "Чингирлау",
                "index": "091200"
            },
            {
                "city": "Жанибек",
                "index": "090500"
            },
            {
                "city": "Таскала",
                "index": "091000"
            }
        ],
        "10": [
            {
                "city": "Караганды",
                "index": "100000"
            },
            {
                "city": "Караганды",
                "index": "101301"
            },
            {
                "city": "Балхаш",
                "index": "100300"
            },
            {
                "city": "Атасу",
                "index": "100500"
            },
            {
                "city": "Аксу-Аюлы",
                "index": "101700"
            },
            {
                "city": "Каркаралинск",
                "index": "100800"
            },
            {
                "city": "Темиртау",
                "index": "101400"
            },
            {
                "city": "Абай",
                "index": "100100"
            },
            {
                "city": "Шахтинск",
                "index": "101600"
            },
            {
                "city": "Актогай",
                "index": "100200"
            },
            {
                "city": "Осакаровка",
                "index": "101000"
            },
            {
                "city": "Ботакара",
                "index": "100400"
            },
            {
                "city": "Сарань",
                "index": "101200"
            },
            {
                "city": "Киевка",
                "index": "100900"
            },
            {
                "city": "Жезказган",
                "index": "100600"
            },
            {
                "city": "Агадырь",
                "index": "101712"
            }
        ],
        "11": [
            {
                "city": "Боровское",
                "index": "111300"
            },
            {
                "city": "Костанай",
                "index": "110000",
                "idrive": true
            },
            {
                "city": "Рудный",
                "index": "111500"
            },
            {
                "city": "Аркалык",
                "index": "110300"
            },
            {
                "city": "Камысты",
                "index": "110800"
            },
            {
                "city": "Костанай",
                "index": "110000"
            },
            {
                "city": "Житикара",
                "index": "110700"
            },
            {
                "city": "Караменды",
                "index": "111400"
            },
            {
                "city": "Сарыколь",
                "index": "111600"
            },
            {
                "city": "Денисовка",
                "index": "110500"
            },
            {
                "city": "Карасу",
                "index": "111000"
            },
            {
                "city": "Амангельды",
                "index": "110200"
            },
            {
                "city": "Карабалык",
                "index": "110900"
            },
            {
                "city": "Федоровка",
                "index": "111900"
            },
            {
                "city": "Узунколь",
                "index": "111800"
            },
            {
                "city": "Тарановское",
                "index": "111700"
            },
            {
                "city": "Лисаковск",
                "index": "111200"
            },
            {
                "city": "Аулиеколь",
                "index": "110400"
            },
            {
                "city": "Тургай",
                "index": "110600"
            }
        ],
        "12": [
            {
                "city": "Кызылорда",
                "index": "120000"
            },
            {
                "city": "Казалы",
                "index": "120400"
            },
            {
                "city": "Кармакшы",
                "index": "120500"
            },
            {
                "city": "Шиели",
                "index": "120700"
            },
            {
                "city": "Арал",
                "index": "120100"
            },
            {
                "city": "Жанакорган",
                "index": "120300"
            },
            {
                "city": "Жалагаш",
                "index": "120200"
            },
            {
                "city": "Теренозек",
                "index": "120600"
            }
        ],
        "13": [
            {
                "city": "Актау",
                "index": "130000"
            },
            {
                "city": "Шетпе",
                "index": "130400"
            },
            {
                "city": "Бейнеу",
                "index": "130100"
            },
            {
                "city": "Жанаозен",
                "index": "130200"
            }
        ],
        "14": [
            {
                "city": "Павлодар",
                "index": "140000"
            },
            {
                "city": "Аксу",
                "index": "140100"
            },
            {
                "city": "Баянауль",
                "index": "140300"
            },
            {
                "city": "Экибастуз",
                "index": "141206"
            },
            {
                "city": "Акку",
                "index": "140700"
            },
            {
                "city": "Щербакты",
                "index": "141100"
            },
            {
                "city": "Теренколь ",
                "index": "140600"
            },
            {
                "city": "Железинка",
                "index": "140400"
            },
            {
                "city": "Актогай",
                "index": "100200"
            },
            {
                "city": "Иртышск",
                "index": "140500"
            },
            {
                "city": "Коктобе",
                "index": "140800"
            },
            {
                "city": "Успенка",
                "index": "141000"
            }
        ],
        "15": [
            {
                "city": "Петропавловск",
                "index": "150000"
            },
            {
                "city": "Явленка",
                "index": "150500"
            },
            {
                "city": "Пресновка",
                "index": "150600"
            },
            {
                "city": "Тайынша",
                "index": "151000"
            },
            {
                "city": "Булаево",
                "index": "150800"
            },
            {
                "city": "Бишкуль",
                "index": "150700"
            },
            {
                "city": "Саумалколь",
                "index": "150100"
            },
            {
                "city": "Талшик",
                "index": "150200"
            },
            {
                "city": "Смирново",
                "index": "150300"
            },
            {
                "city": "Сергеевка",
                "index": "151300"
            },
            {
                "city": "Тимирязево",
                "index": "151100"
            },
            {
                "city": "Кишкенеколь",
                "index": "151200"
            },
            {
                "city": "Мамлютка",
                "index": "150900"
            },
            {
                "city": "Новоишимское",
                "index": "150400"
            }
        ],
        "16": []
    }
]


export default cityData
